import React,  { useEffect, useState, useMemo } from "react";
import { Amplify, API } from 'aws-amplify';
import './App.css';

import {
    useParams
  } from "react-router-dom";


import mixpanel from 'mixpanel-browser';
mixpanel.init('886851446d9aca5c45817555befcecde');

function Trends(props) {
    const [trends, setTrends] = useState(null);
    const [visibleTrends, setVisibleTrends] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchTrends() {
            try {
                const trendData = await API.post('rechargedAIApi', '/getTrends', {body: {}});
                let trendList = trendData.trends.Items;
                //each new formatted trend will have the following structure:
                // {
                //     trend: 'trend name',
                //     category: 'category name',
                // }
                let trendListFormatted = [];
                let approvedTrendCategories = ['LEADERS', 'UNITED STATES', 'THE AMERICAS', 'ASIA', 'CHINA', 'MIDDLE EAST & AFRICA', 'EUROPE', 'BRITAIN', 'INTERNATIONAL', 'BUSINESS', 'FINANCE & ECONOMICS', 'SCIENCE & TECHNOLOGY', 'CULTURE'];
                console.log('trendList');
                console.log(trendList);
                trendList.forEach((trend) => {
                    console.log('trend');
                    console.log(trend);
                    let oldTrendText = trend.trends_text.S;
                    //remove the whitespace from the beginning of the article
                    oldTrendText = oldTrendText.replace(/^\s+/g, '');
                    //find any numeration such as 1. 2. 3. and enter newline before it
                    oldTrendText = oldTrendText.replace(/(\d+\.)/g, '\n$1');
                    //breakdown 1. 2. 3. into new trend objects
                    let trendTextArray = oldTrendText.split('\n');
                    trendTextArray.forEach((trendText) => {
                        let trendTextSplit = trendText.split('.');
                        let trendTextNumber = trendTextSplit[0];
                        let trendTextName = trendTextSplit[1];
                        if (trendTextName && trendTextName.length > 0 && trendTextName !== ' ') {
                            trendTextName = trendTextName.replace(/^\s+/g, '');
                            //don't include if the first letter is not an alphabet character
                            if (trendTextName.length > 0 && trendTextName[0].match(/[a-z]/i)) {
                                //if the trend category is approved, add it to the list
                                if (approvedTrendCategories.includes(trend.category_name.S)) {
                                    let news_title = trend.news_title.S.split('|')[0];
                                    trendListFormatted.push({
                                        trend: trendTextName,
                                        category: trend.category_name.S,
                                        news_title: news_title,
                                    });
                                }
                            }
                        }
                    });
                });
                console.log("articleListFormatted");
                console.log(trendListFormatted);
                //randomize the order of the trends
                let trendListFormattedRandomized = trendListFormatted.sort(() => Math.random() - 0.5);
                setTrends(trendListFormatted);
                //first 50 trends
                setVisibleTrends(trendListFormatted.slice(0, 50));
                setLoading(false);
                // setTrends(trendData.trends[0]);
            } catch (err) {
                console.log(`error: ${err}`);
            }
        }
        fetchTrends();
        mixpanel.track('Page Visit', {
            page: 'Trends View',
        });
    }, []);

    // const articleView = useMemo(() => {
    //     if (loading) return <div>Loading...</div>;
    //     if (error) return <div>Error fetching article</div>;
    //     if (article) {
    //         // mixpanel.track('Article Viewed', {
    //         //     'Article ID': article.id,
    //         //     'Article Title': article.title,
    //         //     'Article Category': article.category
    //         // });
    //         return (
    //             <div>
    //                 <h1>{article.title}</h1>
    //                 <p>{article.subtitle}</p>
    //                 <p>{article.text}</p>
    //             </div>
    //         );
    //     }
    // }, [article, loading, error]);

    if (loading) return(
        <div>
            <div id="uni_newsletter" class="uni-newsletter uk-section uk-section-xlarge@m uk-panel" style={{marginTop: '50px'}}>
                    <div class="uk-container uk-flex-center uk-text-center" style={{display: 'block'}}>
                        <div>
                        <h1 class="uk-h3 uk-h1@m uk-margin-remove uk-font-display" style={{textAlign: 'center'}}><span class="uk-text-gradient">Trends</span></h1>
                        <p class="uk-text-lead uk-margin-remove">AI Analyzed trends from Bloomberg and Economist articles. Updated weekly.</p>
                                <p>Loading...</p>
                                <div class="uk-margin-small-right" uk-spinner="ratio: 2"></div>
                                {/* spinner */}
                            </div>
                            </div>
                            </div>
            </div>
    );

    return (
        <div>
            <div id="uni_newsletter" class="uni-newsletter uk-section uk-section-xlarge@m uk-panel" style={{marginTop: '50px'}}>
                <div class="uk-container uk-flex-center uk-text-center" style={{display: 'block'}}>
                    <div>
                <h1 class="uk-h3 uk-h1@m uk-margin-remove uk-font-display" style={{textAlign: 'center'}}><span class="uk-text-gradient">Trends</span></h1>
                <p class="uk-text-lead uk-margin-remove">AI Analyzed trends from Bloomberg and Economist articles. Updated weekly.</p>
                </div>

                { //comment out the below to remove the category filter
                // <div class="Rw" style={{marginTop: "30px"}}> 

                // <div class="Col">
                // <div class="uni-phase uk-panel uk-overflow-hidden uk-card uk-card-small uk-card-medium@m uk-card-border uk-box-shadow-xsmall uk-radius-medium uk-radius-large@m dark:uk-background-white-5" style={{maxWidth:"25%"}}>
                //     <div class="uk-panel">
                //     <span class="uni-phase-count uk-text-overline uk-text-gradient">Phase 01</span>
                //     {/* <h3 class="uni-phase-title uk-h4 uk-h3@m uk-font-display uk-text-uppercase uk-margin-remove-bottom">Planning</h3> */}
                //     <p class="uni-phase-description uk-text-large@m">Quality comes first. we took our time to plan out everything and build our production pipeline for a good quality artworks.</p>
                //     </div>
                // </div>
                // <div class="uni-phase uk-panel uk-overflow-hidden uk-card uk-card-small uk-card-medium@m uk-card-border uk-box-shadow-xsmall uk-radius-medium uk-radius-large@m dark:uk-background-white-5" style={{maxWidth:"25%"}}>
                //     <div class="uk-panel">
                //     <span class="uni-phase-count uk-text-overline uk-text-gradient">Phase 01</span>
                //     {/* <h3 class="uni-phase-title uk-h4 uk-h3@m uk-font-display uk-text-uppercase uk-margin-remove-bottom">Planning</h3> */}
                //     <p class="uni-phase-description uk-text-large@m">Quality comes first. we took our time to plan out everything and build our production pipeline for a good quality artworks.</p>
                //     </div>
                // </div>
                // <div class="uni-phase uk-panel uk-overflow-hidden uk-card uk-card-small uk-card-medium@m uk-card-border uk-box-shadow-xsmall uk-radius-medium uk-radius-large@m dark:uk-background-white-5" style={{maxWidth:"25%"}}>
                //     <div class="uk-panel">
                //     <span class="uni-phase-count uk-text-overline uk-text-gradient">Phase 01</span>
                //     {/* <h3 class="uni-phase-title uk-h4 uk-h3@m uk-font-display uk-text-uppercase uk-margin-remove-bottom">Planning</h3> */}
                //     <p class="uni-phase-description uk-text-large@m">Quality comes first. we took our time to plan out everything and build our production pipeline for a good quality artworks.</p>
                //     </div>
                // </div>
                // </div>
                // </div>
                }

<div id="columns">
                {//for each visible trend
                    visibleTrends.map((trend, index) => {
                        return (
                            <div class="uni-phase uk-panel uk-overflow-hidden uk-card uk-card-small uk-card-medium@m uk-card-border uk-box-shadow-xsmall uk-radius-medium uk-radius-large@m dark:uk-background-white-5">
                                <div class="uk-panel">
                                <span class="uni-phase-count uk-text-overline uk-text-gradient">{
                                    trend.category
                                }</span>
                                {/* <h3 class="uni-phase-title uk-h4 uk-h3@m uk-font-display uk-text-uppercase uk-margin-remove-bottom">Planning</h3> */}
                                {
                                    //if trend category is LEADERS, SHOW THIS PIC
                                    trend.category === "LEADERS" ?
                                    <div><img src='../assets/images/icons/leaders.png' alt="leaders" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "UNITED STATES" ?
                                    <div><img src='../assets/images/icons/united_states.png' alt="united_states" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "THE AMERICAS" ?
                                    <div><img src='../assets/images/icons/americas.png' alt="the_americas" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "ASIA" ?
                                    <div><img src='../assets/images/icons/asia.png' alt="asia" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "CHINA" ?
                                    <div><img src='../assets/images/icons/china.png' alt="china" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "MIDDLE EAST & AFRICA" ?
                                    <div><img src='../assets/images/icons/africa.png' alt="middle_east_africa" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "EUROPE" ?
                                    <div><img src='../assets/images/icons/europe.png' alt="europe" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "BRITAIN" ?
                                    <div><img src='../assets/images/icons/britain.png' alt="britain" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "INTERNATIONAL" ?
                                    <div><img src='../assets/images/icons/international.png' alt="international" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "BUSINESS" ?
                                    <div><img src='../assets/images/icons/business.png' alt="business" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "FINANCE & ECONOMICS" ?
                                    <div><img src='../assets/images/icons/economy.png' alt="finance" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "SCIENCE & TECHNOLOGY" ?
                                    <div><img src='../assets/images/icons/science.png' alt="science" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "CULTURE" ?
                                    <div><img src='../assets/images/icons/culture.png' alt="culture" style={{width: "20%", height: "auto"}}/></div>
                                    : null
                                }
                                <p class="uni-phase-description uk-text-large@m">{
                                    trend.trend
                                }</p>
                                <hr class="uk-width-2xsmall uk-margin-medium@m"></hr>
                                <ul class="uk-list uk-text-small uk-text-medium@m">
                                            <li class="uk-flex-middle"><i class="uk-icon uk-icon-xsmall uk-icon-small@m unicon-event uk-margin-xsmall-right uk-text-gradient"></i>
                                            {trend.news_title}
                                            </li>
                                </ul>
                                </div>
                            </div>
                        );
                    }
                )}
	</div>

                    {/* <img src="/../assets/images/robotsatwork.png" alt="robots at work" style={{width: '100%', height: '50vh', objectFit:'cover', objectPosition:'50% 30%'}}/>
                    <div class="uk-panel uk-flex-center uk-text-center uk-card uk-card-medium uk-card-xlarge@m uk-card-border uk-overflow-hidden uk-box-shadow-xsmall uk-radius-medium uk-radius-large@m dark:uk-background-white-5">
                        <div class="uk-panel uk-position-z-index">
                            <h1 class="uk-h3 uk-h1@m uk-margin-remove uk-font-display"><span class="uk-text-gradient">{
                                article ? article.title.S : 'Loading...'
                            }</span></h1>
                            <p style={{whiteSpace: 'pre-line'}} class="uk-text-large@m uk-margin-small-top">{
                                article ? article.text.S : 'Loading...'
                            }</p>
                            <div class="uk-grid-2xsmall uk-grid uk-child-width-auto@m uk-flex-middle uk-flex-center uk-margin-small-top uk-margin-medium-top@m" data-uk-grid="">
                                <br/><br/><br/>
                                <h3 class="uni-phase-title uk-h4 uk-h3@m uk-font-display uk-text-uppercase uk-margin-remove-bottom">Get daily AI ideas to your mailbox👇<br/></h3>
                                <div>
                                    <input type="email" name="email" autocomplete="off" class="uk-input uk-form-medium uk-form-large@m uk-width-large@m" placeholder="Your email.." value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </div> */}
                                {/* <div>
                                    <button 
                                    disabled={!isValidEmail}
                                    onClick={() => {
                                        mixpanel.track('Email Subscription', {
                                            email: email,
                                            slug: slug
                                        });
                                        alert('Thank you for signing up for our newsletter!');
                                    }}
                                    type="button" class="uk-button uk-button-medium uk-button-large@m uk-button-gradient uk-width-1-1">Sign up</button>
                                </div> */}
                            {/* </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Trends;