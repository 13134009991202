/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://56iczixecbgjzdbldkdyediyoq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-jdi4eimbb5cvfggdlqvazfk2qu",
    "aws_cloud_logic_custom": [
        {
            "name": "rechargedAIApi",
            "endpoint": "https://4j5ddlcpoj.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
