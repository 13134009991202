import logo from './logo.svg';
//useEffect
import React, { useState, useEffect } from 'react';
//import amplify
import { Amplify, API } from 'aws-amplify';
import './App.css';
import Parallax from 'parallax-js';
import mixpanel from 'mixpanel-browser';
import awsConfig from './aws-exports';
import ArticleView from './ArticleView';
import Trends from './Trends';

import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Link
} from "react-router-dom";


mixpanel.init('886851446d9aca5c45817555befcecde');

Amplify.configure(awsConfig);

export const AnimatedInput = ({placeholder: passedPlaceholder = "", ...passedProps}) => {
  const [placeholder, setPlaceholder] = useState(passedPlaceholder.slice(0, 0));
  const [placeholderIndex, setPlaceholderIndex] = useState(0);


  useEffect(() => {
      const intr = setInterval(() => {
          setPlaceholder(passedPlaceholder.slice(0, placeholderIndex));
          if (placeholderIndex + 1 > passedPlaceholder.length) {
              setPlaceholderIndex(0)
          } else {
              setPlaceholderIndex(placeholderIndex + 1)
          }
      }, 150);
      return () => {
          clearInterval(intr);
      }
  },);


  return <input {...passedProps} placeholder={placeholder}/>
};

//animated input for multiple placeholders, accets a placeholder array
export const AnimatedInput2 = ({placeholder: passedPlaceholder = "", ...passedProps}) => {
  const [placeholder, setPlaceholder] = useState(passedPlaceholder[0].slice(0, 0));
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [placeholderArrayIndex, setPlaceholderArrayIndex] = useState(0);
  
  useEffect(() => {
      const intr = setInterval(() => {
          setPlaceholder(passedPlaceholder[placeholderArrayIndex].slice(0, placeholderIndex));
          if (placeholderIndex + 1 > passedPlaceholder[placeholderArrayIndex].length) {
              setPlaceholderIndex(0)
              if (placeholderArrayIndex + 1 > passedPlaceholder.length - 1) {
                  setPlaceholderArrayIndex(0)
              } else {
                  setPlaceholderArrayIndex(placeholderArrayIndex + 1)
              }
          } else {
              setPlaceholderIndex(placeholderIndex + 1)
          }
      }, 150);
      return () => {
          clearInterval(intr)
      }
  },);

  return <input {...passedProps} placeholder={'Example: '+placeholder}/>
};

function AppContent(props) {
  let location = useLocation();

  return (
        <TransitionGroup>
        <CSSTransition
            key={useLocation().pathname}
            classNames="fade"
            timeout={3000}
          >
        <Routes location={useLocation()}>
            <Route path="/" element={<Landing />} />
            <Route path="/article/:slug" element={<ArticleView />} />
            <Route path='/trends' element={<Trends />} />
        </Routes>
        </CSSTransition>
        </TransitionGroup>
    // <Landing></Landing>
  );
}

const App = () => {
  return (
    <Router>
       {/* <p>? User: {user ? JSON.stringify(user.attributes) : 'None'}</p> */}
      <AppContent />
    </Router>
  );
};

function Landing() {

  //state to store input value
  const [inputValue, setInputValue] = useState('');
   //isLoading
   const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Your code here
    var scene = document.getElementsByClassName('parallax');
    var parallax = new Parallax(scene[0]);
    mixpanel.track('Page Visit', { 'Page Name': 'Landing Page' });
  }, []);

  //onclick function for the button
  function handleClick(input_value) {
    console.log(input_value);
    mixpanel.track('Article Generation Request', {
      'Customization': input_value
    });
    const conversion = API.post('rechargedAIApi', '/generateArticle', {
      body: {
        userInput: input_value
      }
    });
    setIsLoading(true);
    conversion.then((response) => {
      console.log(response);
      mixpanel.track('Article Generation Success', {
        'Article Title': response
      });
      let slug = response["slug"];
      window.location.href = "/article/" + slug;
      // window.location.href = '/article/' + response;
    }
    ).catch((error) => {
      console.log(error);
      setIsLoading(false);
      // window.location.href = '/error';
    }
    );
  }
  
  return (
    <div>
            <div id="uni_hero" className="uni-hero uk-section-2xlarge uk-padding-large-bottom uk-padding-2xlarge-bottom@m uk-panel swiper-parent">
                <div className="uk-position-top uk-position-z-index-negative uk-overflow-hidden uk-blend-soft-light bluelights" data-uk-height-viewport="">
                    <img className="uk-position-top-left uk-position-fixed uk-blur-large" style={{left: '-4%', top: '-4%'}} width="500" src="../assets/images/gradient-circle.svg" alt="Circle"></img>
                    <img className="uk-position-bottom-right uk-position-fixed uk-blur-large" style={{right: '-4%', bottom: '-4%'}} width="500" src="../assets/images/gradient-circle.svg" alt="Circle"></img>
                </div>
                <div className="uk-position-top uk-position-z-index-negative uk-blend-hard-light" data-uk-height-viewport="">
                    <img className="uk-position-top uk-position-fixed uk-blur-large" style={{left: '25%', top: '-80%'}} width="700" src="../assets/images/gradient-circle.svg" alt="Circle"></img>
                </div>
                <div className="uk-panel uk-position-z-index">
                    <div className="uk-container">
                        <div className="uk-panel">
                            <div className="uk-grid uk-grid-2xlarge uk-flex-middle uk-flex-between" data-uk-grid data-uk-height-viewport="offset-top: true; offset-bottom: 20;">
                                <div className="uk-width-6-12@m">
                                    <div className="uk-panel uk-position-z-index uk-text-center" data-anime="opacity:[0, 1]; translateY:[24, 0]; onview: true; delay: 100;">
                                        {/* <img className="uk-position-top-left" width="44" src="../assets/images/objects/ethereum-01.png" alt="object" style={{top: '-20%', left: '50%'}} data-anime="opacity:[0, 1]; scale:[0, 1]; onview: true; delay: 400;"></img> */}
                                        {/* <img className="uk-position-left" width="16" src="../assets/images/objects/circle-01.png" alt="object" style={{top: '16%', left: '-4%'}} data-anime="opacity:[0, 1]; scale:[0, 1]; onview: true; delay: 420;"></img> */}
                                        {/* <img className="uk-position-bottom-left" width="24" src="../assets/images/objects/circle-03.png" alt="object" style={{bottom: '-16%', left: '16%'}} data-anime="opacity:[0, 1]; scale:[0, 1]; onview: true; delay: 440;"></img> */}
                                        <h2 className="uk-h2 uk-heading-d2@m uk-font-display uk-text-uppercase uk-margin-remove-top">AI-Powered Ideas</h2>
                                        <h4>Tailored to you. Based on daily news.</h4>
                                        <div class="module-border-wrap">
                                          {/* <input id="email-f" type="email" name="email" autocomplete="off" class="uk-input uk-form-medium@m uk-width-medium@m" placeholder="Example: I need startup ideas.."></input> */}
                                          <AnimatedInput2 
                                          onChange={(e) => setInputValue(e.target.value)}
                                          id="email-f" type="email" name="email" autocomplete="off" class="uk-input uk-form-medium@m uk-width-medium@m" 
                                          placeholder={["I need a startup idea", "I am a marketer at a Pharma firm", "I need investment ideas"]}/>
                                          </div> <br/>
                                        { !isLoading ? <button 
                                        onClick={() => handleClick(inputValue)}
                                        className="uk-button uk-button-medium uk-button-large@m uk-button-gradient">
                                            <span>Generate now</span>
                                        </button> : 
                                        //purple gradient spinner
                                        <div class="uk-flex uk-flex-center"><div data-uk-spinner></div></div> }
                                        <img className="uk-width-2xsmall uk-flex-center uk-margin-auto uk-margin-medium-top uk-margin-large-top@m uk-visible@m" src="../assets/images/divider-01.svg" alt="Divider"></img>
                                        <div className="uk-grid uk-grid-small uk-grid-medium@m uk-flex-center uk-flex-between@m uk-child-width-1-3 uk-margin-medium-top uk-margin-large-top@m" data-uk-grid="">
                                            <div>
                                                <div className="uk-panel">
                                                    <h4 className="uk-h3 uk-margin-2xsmall uk-font-display">1,000+</h4>
                                                    <span className="uk-text-small uk-text-muted">Analysis generated</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-panel">
                                                    <h4 className="uk-h3 uk-margin-2xsmall uk-font-display">20+</h4>
                                                    <span className="uk-text-small uk-text-muted">quality sources</span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-panel">
                                                    <h4 className="uk-h3 uk-margin-2xsmall uk-font-display">10x</h4>
                                                    <span className="uk-text-small uk-text-muted">Your capacity</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-6-12@m uk-flex-center">
                                    <div className="ideabg uk-panel" data-anime="opacity:[0, 1]; translateY:[24, 0]; onview: true; delay: 200;">
                                        {/* <img className="uk-position-left uk-text-primary" width="44" src="../assets/images/objects/bitcoin-01.png" alt="object" style={{top: '75%', left: '-20%'}} data-uk-svg="" data-anime="opacity:[0, 1]; scale:[0, 1]; onview: true; delay: 460;"></img> */}
                                        {/* <img className="uk-position-right" width="28" src="../assets/images/objects/x.png" alt="object" style={{top: '16%', right: '-16%'}} data-uk-svg="" data-anime="opacity:[0, 1]; scale:[0, 1]; onview: true; delay: 480;"></img> */}
                                        {/* <img className="uk-position-left uk-opacity-10" width="400" src="../assets/images/blob-dashed.svg" alt="Blog dashed" style={{top: '-6%', left: '-16%', fill: 'transparent'}} data-uk-svg=""></img> */}
                                        {/* <img className="uk-position-bottom-right uk-opacity-10" width="400" src="../assets/images/blob-dashed.svg" alt="Blog dashed" style={{bottom: '4%', right: '-16%', fill: 'transparent'}} data-uk-svg=""></img> */}
                                        <div className="uk-panel uk-width-xlarge uk-margin-auto">
                                            <div className="parallax">
                                              <img data-depth="0.10" src="../assets/images/rechargedbg4.png" alt="shape" className="s-img" style={{zIndex: "1 !important"}}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img class="uk-width-2xsmall uk-flex-center uk-margin-auto uk-margin-remove-bottom" src="../assets/images/divider-01.svg" alt="Divider"></img>

            <div id="uni_minting" class="uni-minting uk-section uk-section-xlarge@m uk-panel">
                <div class="uk-container">
                    <div class="uk-panel uk-margin-medium-top uk-margin-2xlarge-top@m">
                        {/* <img class="uk-position-top-left uk-text-secondary" width="16" src="../assets/images/objects/circle-01.png" alt="Object" style="{top: -16%; left: 8%"></img> */}
                        {/* <img class="uk-position-bottom-right uk-text-primary" width="24" src="../assets/images/objects/circle-02.png" alt="Object" style="bottom: 16%; right: -8%"></img> */}
                        {/* <img class="uk-position-bottom-left uk-text-muted" width="28" src="../assets/images/objects/x.png" alt="Object" style="bottom: 16%; left: -8%"></img> */}
                        <div class="uk-grid-medium uk-grid-2xlarge@m uk-flex-middle uk-grid" data-uk-grid="">
                            <div class="uk-panel uk-width-2-5@m">
                                <header class="uk-panel uk-text-center uk-text-left@m">
                                    <h2 class="uk-h3 uk-h1@m uk-font-display uk-text-uppercase">How does it <span class="uk-text-gradient">work?</span></h2>
                                    <img class="uk-width-2xsmall uk-margin@m uk-visible@m" src="../assets/images/divider-01.svg" alt="Divider"></img>
                                    <p class="uk-text-xlarge@m">We combine the latest AI models + quality news + your intention and generate opportunity ideas!</p>
                                </header>
                            </div>
                            <div class="uk-panel uk-width-expand@m">
                                <div class="uk-grid-small uk-grid@m uk-child-width-1-2@m uk-width-2xlarge uk-grid" data-uk-grid="masonry: true;" data-anime="targets: > * > *; opacity:[0, 1]; translateY:[24, 0]; onview: -250; delay: anime.stagger(100);">
                                    <div>
                                        <div class="uni-minting-item uk-card uk-card-medium uk-card-border uk-card-default uk-height-large@m uk-radius-medium uk-radius-large@m dark:uk-background-white-5">
                                            <div class="uk-grid uk-grid-small@m uk-flex-column uk-flex-top" data-uk-grid>
                                                <div class="uk-width-auto">
                                                    <div class="uk-card uk-card-xsmall uk-background-gradient uk-flex-middle uk-flex-center uk-radius-medium">
                                                        <i class="uk-icon-small uk-icon-medium@m uk-text-white unicon-machine-learning"></i>
                                                    </div>
                                                </div>
                                                <div class="uk-width-expand@m">
                                                    <div class="content uk-panel">
                                                        <h3 class="title uk-h5 uk-font-display uk-text-uppercase uk-margin-remove">Latest AI Models</h3>
                                                        <p class="description uk-text-muted dark:uk-text-gray-40">We use the latest and greatest in AI technology to read and interpret articles.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="uni-minting-item uk-card uk-card-medium uk-card-border uk-card-default uk-height-large@m uk-radius-medium uk-radius-large@m uk-margin-large-top@m dark:uk-background-white-5">
                                            <div class="uk-grid uk-grid-small@m uk-flex-column uk-flex-top" data-uk-grid>
                                                <div class="uk-width-auto">
                                                    <div class="uk-card uk-card-xsmall uk-background-gradient uk-flex-middle uk-flex-center uk-radius-medium">
                                                        <i class="uk-icon-small uk-icon-medium@m uk-text-white unicon-globe"></i>
                                                    </div>
                                                </div>
                                                <div class="uk-width-expand@m">
                                                    <div class="content uk-panel">
                                                        <h3 class="title uk-h5 uk-font-display uk-text-uppercase uk-margin-remove">QUALITY NEWS</h3>
                                                        <p class="description uk-text-muted dark:uk-text-gray-40">We digest hundreds of articles a day from the top news sources like The Economist, Bloomberg, etc.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="uni-minting-item uk-card uk-card-medium uk-card-border uk-card-default uk-height-large@m uk-radius-medium uk-radius-large@m dark:uk-background-white-5">
                                            <div class="uk-grid uk-grid-small@m uk-flex-column uk-flex-top" data-uk-grid>
                                                <div class="uk-width-auto">
                                                    <div class="uk-card uk-card-xsmall uk-background-gradient uk-flex-middle uk-flex-center uk-radius-medium">
                                                        <i class="uk-icon-small uk-icon-medium@m uk-text-white unicon-user"></i>
                                                    </div>
                                                </div>
                                                <div class="uk-width-expand@m">
                                                    <div class="content uk-panel">
                                                        <h3 class="title uk-h5 uk-font-display uk-text-uppercase uk-margin-remove">Tailored to you</h3>
                                                        <p class="description uk-text-muted dark:uk-text-gray-40">You tell us what you're looking for and we find the best opportunities for you!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="uni-minting-item uk-card uk-card-medium uk-card-border uk-card-default uk-height-large@m uk-radius-medium uk-radius-large@m dark:uk-background-white-5">
                                            <div class="uk-grid uk-grid-small@m uk-flex-column uk-flex-top" data-uk-grid>
                                                <div class="uk-width-auto">
                                                    <div class="uk-card uk-card-xsmall uk-background-gradient uk-flex-middle uk-flex-center uk-radius-medium">
                                                        <i class="uk-icon-small uk-icon-medium@m uk-text-white unicon-piggy-bank"></i>
                                                    </div>
                                                </div>
                                                <div class="uk-width-expand@m">
                                                    <div class="content uk-panel">
                                                        <h3 class="title uk-h5 uk-font-display uk-text-uppercase uk-margin-remove">INFINITE OPPORTUNITIES</h3>
                                                        <p class="description uk-text-muted dark:uk-text-gray-40">We generate an infinite number of ideas for you to explore!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
  );
}

export default App;
