import React,  { useEffect, useState, useMemo } from "react";
import { Amplify, API } from 'aws-amplify';
import './App.css';

import {
    useParams
  } from "react-router-dom";


import mixpanel from 'mixpanel-browser';
mixpanel.init('886851446d9aca5c45817555befcecde');

function ArticleView(props) {
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [trend, setTrend] = useState(null);
    let { slug } = useParams();

    useEffect(() => {
        console.log(`slug: ${slug}`);
        //fetch the article
        async function fetchArticle() {
            try {
                const articleData = await API.post('rechargedAIApi', '/getArticle', {body: {slug: slug}});
                console.log(`articleData: ${JSON.stringify(articleData)}`);
                let articleText = articleData.article.text.S;
                //remove the whitespace from the beginning of the article
                articleText = articleText.replace(/^\s+/g, '');
                //find any numeration such as 1. 2. 3. and enter newline before it
                articleText = articleText.replace(/(\d+\.)/g, '\n$1');
                articleData.article.text.S = articleText;
                let oldTrendText = articleData.article.trends.S.replace(/^\s+/g, '');
                //find any numeration such as 1. 2. 3. and enter newline before it
                oldTrendText = oldTrendText.replace(/(\d+\.)/g, '\n$1');
                setArticle(articleData.article);
                setTrend({
                    category: articleData.article.trends_cat_name.S,
                    trend: oldTrendText,
                    news_title: articleData.article.trends_news_name.S,
                });
                setLoading(false);
            } catch (err) {
                console.log(`error: ${err}`);
                setError(true);
                setLoading(false);
            }
        }
        fetchArticle();
        mixpanel.track('Page Visit', {
            page: 'Article View',
        });
    }, []);

    //on submit email
    const onSubmitEmail = async (e) => {
        e.preventDefault();
        //send email to mixpanel
        mixpanel.track('Email Subscription', {
            email: email,
            slug: slug,
        });
        //send email to backend
        try {
            const emailData = await API.post('rechargedAIApi', '/submitEmail', {body: {email: email}});
            console.log(`emailData: ${JSON.stringify(emailData)}`);
        } catch (err) {
            console.log(`error: ${err}`);
        }
    }

    //email validator
    useEffect(() => {
        if (email.length > 0) {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            setIsValidEmail(re.test(String(email).toLowerCase()));
        } else {
            setIsValidEmail(false);
        }
    }, [email]);

    // const articleView = useMemo(() => {
    //     if (loading) return <div>Loading...</div>;
    //     if (error) return <div>Error fetching article</div>;
    //     if (article) {
    //         // mixpanel.track('Article Viewed', {
    //         //     'Article ID': article.id,
    //         //     'Article Title': article.title,
    //         //     'Article Category': article.category
    //         // });
    //         return (
    //             <div>
    //                 <h1>{article.title}</h1>
    //                 <p>{article.subtitle}</p>
    //                 <p>{article.text}</p>
    //             </div>
    //         );
    //     }
    // }, [article, loading, error]);

    return (
        <div>
            <div id="uni_newsletter" class="uni-newsletter uk-section uk-section-xlarge@m uk-panel" style={{marginTop: '50px'}}>
                <div class="uk-container">
                    <img src="/../assets/images/robotsatwork.png" alt="robots at work" style={{width: '100%', height: '50vh', objectFit:'cover', objectPosition:'50% 30%'}}/>
                    <div class="uk-panel uk-flex-center uk-text-center uk-card uk-card-medium uk-card-xlarge@m uk-card-border uk-overflow-hidden uk-box-shadow-xsmall uk-radius-medium uk-radius-large@m dark:uk-background-white-5">
                        <div class="uk-panel uk-position-z-index">
                            <h1 class="uk-h3 uk-h1@m uk-margin-remove uk-font-display"><span class="uk-text-gradient">{
                                article ? article.title.S : 'Loading...'
                            }</span></h1>
                            { article ?
                            <div class="uk-panel uk-margin-medium-top uk-margin-2xlarge-top@m">
                                <ul class="uk-card uk-card-small uk-card-small@m uk-radius uk-radius-large@m uk-box-shadow-xsmall dark:uk-background-white-5 uk-accordion" data-uk-accordion="collapsible: true" data-anime="opacity:[0, 1]; translateY:[24, 0]; onview: true; delay: 100;" style={{width: '100%', opacity: 1, transform: 'translateY(0px)'}}>
                                    <li class="uk-collapsed">
                                        <a class="uk-accordion-title uk-h5@m" href="#" aria-expanded="true">Relevant Trends:</a>
                                        <div class="uk-accordion-content uk-padding-small-bottom">
                                            <span class="uni-phase-count uk-text-overline uk-text-gradient">{
                                    trend.category
                                }</span>
                                {/* <h3 class="uni-phase-title uk-h4 uk-h3@m uk-font-display uk-text-uppercase uk-margin-remove-bottom">Planning</h3> */}
                                {
                                    //if trend category is LEADERS, SHOW THIS PIC
                                    trend.category === "LEADERS" ?
                                    <div><img src='../assets/images/icons/leaders.png' alt="leaders" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "UNITED STATES" ?
                                    <div><img src='../assets/images/icons/united_states.png' alt="united_states" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "THE AMERICAS" ?
                                    <div><img src='../assets/images/icons/americas.png' alt="the_americas" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "ASIA" ?
                                    <div><img src='../assets/images/icons/asia.png' alt="asia" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "CHINA" ?
                                    <div><img src='../assets/images/icons/china.png' alt="china" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "MIDDLE EAST & AFRICA" ?
                                    <div><img src='../assets/images/icons/africa.png' alt="middle_east_africa" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "EUROPE" ?
                                    <div><img src='../assets/images/icons/europe.png' alt="europe" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "BRITAIN" ?
                                    <div><img src='../assets/images/icons/britain.png' alt="britain" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "INTERNATIONAL" ?
                                    <div><img src='../assets/images/icons/international.png' alt="international" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "BUSINESS" ?
                                    <div><img src='../assets/images/icons/business.png' alt="business" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "FINANCE & ECONOMICS" ?
                                    <div><img src='../assets/images/icons/economy.png' alt="finance" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "SCIENCE & TECHNOLOGY" ?
                                    <div><img src='../assets/images/icons/science.png' alt="science" style={{width: "20%", height: "auto"}}/></div>
                                    : trend.category === "CULTURE" ?
                                    <div><img src='../assets/images/icons/culture.png' alt="culture" style={{width: "20%", height: "auto"}}/></div>
                                    : null
                                }
                                <p style={{whiteSpace: 'pre-line'}} class="uni-phase-description uk-text-large@m">{
                                    trend.trend
                                }</p>
                                <hr class="uk-width-2xsmall uk-margin-medium@m"></hr>
                                <ul class="uk-list uk-text-small uk-text-medium@m">
                                            <li class="uk-flex-middle"><i class="uk-icon uk-icon-xsmall uk-icon-small@m unicon-event uk-margin-xsmall-right uk-text-gradient"></i>
                                            {trend.news_title}
                                            </li>
                                </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div> : null }
                            <p style={{whiteSpace: 'pre-line'}} class="uk-text-large@m uk-margin-small-top">
                            {
                                article ? article.text.S : 'Loading...'
                            }</p>
                            <div class="uk-grid-2xsmall uk-grid uk-child-width-auto@m uk-flex-middle uk-flex-center uk-margin-small-top uk-margin-medium-top@m" data-uk-grid="">
                                <br/><br/><br/>
                                <h3 class="uni-phase-title uk-h4 uk-h3@m uk-font-display uk-text-uppercase uk-margin-remove-bottom">Get daily AI ideas to your mailbox👇<br/></h3>
                                <div>
                                    <input 
                                    //white border
                                    style={{border: '1px solid #B26FFA', marginTop: '10px'}}
                                    type="email" name="email" autocomplete="off" class="uk-input uk-form-medium uk-form-large@m uk-width-large@m" placeholder="Your email.." value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                                <div>
                                    <button
                                    style={{marginTop: '10px'}}
                                    disabled={!isValidEmail}
                                    onClick={onSubmitEmail}
                                    type="button" class="uk-button uk-button-medium uk-button-large@m uk-button-gradient uk-width-1-1">Sign up</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ArticleView;